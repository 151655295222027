import { useMemo } from 'react';

import { PresetTheLeap } from '..';
import {
  accessibleTextOnUi,
  badgeBgColor,
  badgeBorderColor,
  badgeOutlineColor,
  badgeTextColor,
  bgColorGradient,
  bgColorSolid,
  buttonBgColor,
  buttonOutlineColor,
  cardBgColor,
  iconBgColor,
  iconTextColor,
  outlineColor,
  textColor,
  bgColorBody,
  testimonialBgColor,
  imageOutlineColor,
  footerBgColor,
  cardTextColor,
  customColorVariation,
} from '../lib/colors';
import {
  badgeTextSize,
  textBodySize,
  textBodyWeight,
  textButtonWeight,
  textDisplaySize,
  textDisplayWeight,
  textTitleSize,
  textTitleWeight,
} from '../lib/fonts';
import { imageOverlayGradient, imageOverlaySolid } from '../lib/images';
import {
  buttonShadow,
  cardShadow,
  iconShadow,
  imageShadow,
} from '../lib/shadows';
import {
  badgePadding,
  badgeRadius,
  gapBanner,
  gapSpacer,
} from '../lib/spacing';

import type { Theme, Parameters } from '..';

export const cssVariables = (parameters: Parameters) => ({
  textColor: textColor(parameters),
  textDisplayFont: parameters.displayFont,
  textDisplaySize: textDisplaySize(parameters),
  textDisplayWeight: textDisplayWeight(parameters),
  textTitleSize: textTitleSize(parameters),
  textTitleWeight: textTitleWeight(parameters),
  textBodyFont: parameters.bodyFont,
  textBodySize: textBodySize(parameters),
  textBodyWeight: textBodyWeight(parameters),
  textButtonSize: '1.6rem',
  textButtonWeight: textButtonWeight(parameters),
  gapBanner: gapBanner(parameters),
  gapSpacing: gapSpacer(parameters),

  bgPageSolid: bgColorSolid(parameters),
  bgPageGradient: bgColorGradient(parameters),
  bgBody: bgColorBody(parameters),
  bgFooter: footerBgColor(parameters),
  bgTestimonial: testimonialBgColor(parameters),

  badgePadding: badgePadding(parameters),
  badgeRadius: badgeRadius(parameters),
  badgeBgColor: badgeBgColor(parameters),
  badgeTextColor: badgeTextColor(parameters),
  badgeTextSize: badgeTextSize(parameters),
  badgeOutlineColor: badgeOutlineColor(parameters),
  badgeBorderColor: badgeBorderColor(parameters),
  badgeShadow: buttonShadow(parameters),

  iconRadius: '1.5rem',
  iconBgColor: iconBgColor(parameters),
  iconTextColor: iconTextColor(parameters),
  iconOutlineColor: outlineColor(parameters),
  iconShadow: iconShadow(parameters),

  buttonPadding: '0.5rem 2rem',
  buttonRadius: '2rem',
  buttonBgColor: buttonBgColor(parameters),
  buttonTextColor: accessibleTextOnUi(parameters),
  buttonOutlineColor: buttonOutlineColor(parameters),
  buttonBorderColor: 'transparent',
  buttonShadow: buttonShadow(parameters),

  cardPadding: '1rem',
  cardRadius: '0.5rem',
  cardBgColor: cardBgColor(parameters),
  cardTextColor: cardTextColor(parameters),
  cardOutlineColor: outlineColor(parameters),
  cardBorderColor: 'transparent',
  cardShadow: cardShadow(parameters),

  imageOverlayGradient: imageOverlayGradient(parameters),
  imageOverlaySolid: imageOverlaySolid(parameters),
  imageOutlineColor: imageOutlineColor(parameters),
  imageOutlineWidth: '1px',
  imageShadow: imageShadow(parameters),
});

export const customColorTheme = (
  backgroundColor: string,
  preset = PresetTheLeap
) => {
  const updatedParameters = {
    ...preset.parameters,
    ...customColorVariation(backgroundColor),
  };
  return {
    name: updatedParameters.preset,
    cssVariables: cssVariables(updatedParameters),
    parameters: updatedParameters,
  };
};
// Maybe this function will prove itself unecessary, but I think going with it
// to start with should help we roll out new variables gracefully without breaking forms and rendering.
function useTheme(parameters: Parameters): Theme {
  const theme = useMemo(() => {
    return {
      name: parameters.preset,
      cssVariables: cssVariables(parameters),
      parameters: parameters,
    };
  }, [parameters]);

  return theme;
}

export { useTheme };
